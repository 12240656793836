export const KEY_LIST_REQUEST = "KEY_LIST_REQUEST"
export const KEY_LIST_REQUEST_SUCCESS = "KEY_LIST_REQUEST_SUCCESS"
export const KEY_LIST_REQUEST_ERROR = "KEY_LIST_REQUEST_ERROR"

export const KEY_ADD_REQUEST = "KEY_ADD_REQUEST"
export const KEY_ADD_REQUEST_SUCCESS = "KEY_ADD_REQUEST_SUCCESS"
export const KEY_ADD_REQUEST_ERROR = "KEY_ADD_REQUEST_ERROR"

export const KEY_EDIT_REQUEST = "KEY_EDIT_REQUEST"
export const KEY_EDIT_REQUEST_SUCCESS = "KEY_EDIT_REQUEST_SUCCESS"
export const KEY_EDIT_REQUEST_ERROR = "KEY_EDIT_REQUEST_ERROR"

export const KEY_DELETE_REQUEST = "KEY_DELETE_REQUEST"
export const KEY_DELETE_REQUEST_SUCCESS = "KEY_DELETE_REQUEST_SUCCESS"
export const KEY_DELETE_REQUEST_ERROR = "KEY_DELETE_REQUEST_ERROR"

export const KEY_SHARE_REQUEST = "KEY_SHARE_REQUEST"
export const KEY_SHARE_REQUEST_SUCCESS = "KEY_SHARE_REQUEST_SUCCESS"
export const KEY_SHARE_REQUEST_ERROR = "KEY_SHARE_REQUEST_ERROR"

export const KEY_INVITES_REQUEST = "KEY_INVITES_REQUEST"
export const KEY_INVITES_REQUEST_SUCCESS = "KEY_INVITES_REQUEST_SUCCESS"
export const KEY_INVITES_REQUEST_ERROR = "KEY_INVITES_REQUEST_ERROR"
