import Vue from "vue"
import HTTP from "@/http"

import Vuex from "vuex"
import Language from "./modules/language"
import CountryDB from "./modules/country-db"
import Auth from "./modules/auth"
import Door from "./modules/door"
import Account from "./modules/account"
import Contract from "./modules/contract"
import Payment from "./modules/payment"
import DoorProvider from "./modules/doorprovider"
import IronLogic from "./modules/ironlogic"
import Key from "./modules/key"
import System from "./modules/system"
import Log from "./modules/log"

import { LOGOUT_REQUEST } from "./actions/auth"

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== "production",
    modules: {
        Language,
        CountryDB,
        Auth,
        Door,
        Account,
        Contract,
        Payment,
        DoorProvider,
        IronLogic,
        Key,
        System,
        Log
    }
})

export default store

// Auth routine
if (store.state.Auth.Token) {
    HTTP.defaults.headers.common.Authorization = store.state.Auth.Token
}

// Unauthorized routine
HTTP.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401) {
        // if you ever get an unauthorized, logout the user
        store.dispatch(LOGOUT_REQUEST)
        return Promise.reject(error)
    }
    return Promise.reject(error)
})
