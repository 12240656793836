import HTTP from "@/http"
import Helpers from "@/utils/Helpers"
import i18n from "@/i18n"
import {
    KEY_LIST_REQUEST,
    KEY_LIST_REQUEST_SUCCESS,
    KEY_LIST_REQUEST_ERROR,
    KEY_ADD_REQUEST,
    KEY_ADD_REQUEST_SUCCESS,
    KEY_ADD_REQUEST_ERROR,
    KEY_EDIT_REQUEST,
    KEY_EDIT_REQUEST_SUCCESS,
    KEY_EDIT_REQUEST_ERROR,
    KEY_DELETE_REQUEST,
    KEY_DELETE_REQUEST_SUCCESS,
    KEY_DELETE_REQUEST_ERROR,
    KEY_SHARE_REQUEST,
    KEY_SHARE_REQUEST_SUCCESS,
    KEY_SHARE_REQUEST_ERROR,
    KEY_INVITES_REQUEST,
    KEY_INVITES_REQUEST_SUCCESS,
    KEY_INVITES_REQUEST_ERROR
} from "../actions/key"

const state = {
    Status: null,
    List: [],
    Invites: []
}

const getters = {
    IS_KEYS_LOADED: (state) => !!(
        state.List
        && typeof state.List !== "undefined"
        && state.List.length > 0
    ),
    KEYS_AMOUNT: (state) => state.List.length,
    GET_KEY_BY_ID: (state) => (id) => state.List.find((x) => x.keyId === id),
    IS_INVITES_LOADED: (state) => !!(
        state.Invites
        && typeof state.Invites !== "undefined"
        && state.Invites.length > 0
    ),
    INVITES_AMOUNT: (state) => state.Invites.length
}

const actions = {
    [KEY_LIST_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
        commit(KEY_LIST_REQUEST)
        HTTP({
            url: "key/myownkeys",
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.status === 200) {
                    commit(KEY_LIST_REQUEST_SUCCESS, response.data)
                }
                else {
                    Helpers.notify(
                        "is-danger",
                        i18n.t("Message.Backend.NoData")
                    )
                }
            })
            .catch((error) => {
                reject(error)
                commit(KEY_LIST_REQUEST_ERROR, error)
            })
    }),

    [KEY_ADD_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(KEY_ADD_REQUEST)
        HTTP({
            url: "key",
            data: payload,
            method: "POST"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.data.keyId && response.status === 200) {
                    commit(KEY_ADD_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(KEY_ADD_REQUEST_ERROR, error)
            })
    }),

    [KEY_EDIT_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(KEY_EDIT_REQUEST)
        HTTP({
            url: `key/${payload.Id}`,
            data: payload.Form,
            method: "PUT"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status && response.status === 204) {
                    commit(KEY_EDIT_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(KEY_EDIT_REQUEST_ERROR, error)
            })
    }),

    [KEY_DELETE_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(KEY_DELETE_REQUEST)
        HTTP({
            url: `key/${payload}`,
            method: "DELETE"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status && response.status === 204) {
                    commit(KEY_DELETE_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(KEY_DELETE_REQUEST_ERROR, error)
            })
    }),

    [KEY_SHARE_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(KEY_SHARE_REQUEST)
        HTTP({
            url: `givekey/${payload.Id}`,
            data: payload.Form,
            method: "POST"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status && response.status === 204) {
                    commit(KEY_SHARE_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(KEY_SHARE_REQUEST_ERROR, error)
            })
    }),

    [KEY_INVITES_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(KEY_INVITES_REQUEST)
        HTTP({
            url: `givekey/${payload}`,
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status && response.status === 200 && response.data) {
                    commit(KEY_INVITES_REQUEST_SUCCESS, response.data)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(KEY_INVITES_REQUEST_ERROR, error)
            })
    })
}

const mutations = {
    [KEY_LIST_REQUEST]: (state) => {
        state.Status = "loading"
    },
    [KEY_LIST_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "success"
        state.List = payload
    },
    [KEY_LIST_REQUEST_ERROR]: (state, payload) => {
        state.Status = "error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },

    [KEY_ADD_REQUEST]: (state) => {
        state.Status = "adding"
    },
    [KEY_ADD_REQUEST_SUCCESS]: (state) => {
        state.Status = "add success"
    },
    [KEY_ADD_REQUEST_ERROR]: (state, payload) => {
        state.Status = "add error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },

    [KEY_EDIT_REQUEST]: (state) => {
        state.Status = "editing"
    },
    [KEY_EDIT_REQUEST_SUCCESS]: (state) => {
        state.Status = "edit success"
    },
    [KEY_EDIT_REQUEST_ERROR]: (state, payload) => {
        state.Status = "edit error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },

    [KEY_DELETE_REQUEST]: (state) => {
        state.Status = "deleting"
    },
    [KEY_DELETE_REQUEST_SUCCESS]: (state) => {
        state.Status = "delete success"
    },
    [KEY_DELETE_REQUEST_ERROR]: (state, payload) => {
        state.Status = "delete error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },

    [KEY_SHARE_REQUEST]: (state) => {
        state.Status = "sharing"
    },
    [KEY_SHARE_REQUEST_SUCCESS]: (state) => {
        state.Status = "share success"
    },
    [KEY_SHARE_REQUEST_ERROR]: (state, payload) => {
        state.Status = "share error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },

    [KEY_INVITES_REQUEST]: (state) => {
        state.Status = "invites loading"
    },
    [KEY_INVITES_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "invites load success"
        state.Invites = payload
    },
    [KEY_INVITES_REQUEST_ERROR]: (state, payload) => {
        state.Status = "invites load error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
