export const DOOR_LIST_REQUEST = "DOOR_LIST_REQUEST"
export const DOOR_LIST_REQUEST_SUCCESS = "DOOR_LIST_REQUEST_SUCCESS"
export const DOOR_LIST_REQUEST_ERROR = "DOOR_LIST_REQUEST_ERROR"

export const DOOR_EDIT_REQUEST = "DOOR_EDIT_REQUEST"
export const DOOR_EDIT_REQUEST_SUCCESS = "DOOR_EDIT_REQUEST_SUCCESS"
export const DOOR_EDIT_REQUEST_ERROR = "DOOR_EDIT_REQUEST_ERROR"

export const DOOR_DELETE_REQUEST = "DOOR_DELETE_REQUEST"
export const DOOR_DELETE_REQUEST_SUCCESS = "DOOR_DELETE_REQUEST_SUCCESS"
export const DOOR_DELETE_REQUEST_ERROR = "DOOR_DELETE_REQUEST_ERROR"

export const DOOR_UPDATE_REQUEST = "DOOR_UPDATE_REQUEST"
export const DOOR_UPDATE_REQUEST_SUCCESS = "DOOR_UPDATE_REQUEST_SUCCESS"
export const DOOR_UPDATE_REQUEST_ERROR = "DOOR_UPDATE_REQUEST_ERROR"

export const DOOR_IMAGE_DELETE_REQUEST = "DOOR_IMAGE_DELETE_REQUEST"
export const DOOR_IMAGE_DELETE_REQUEST_SUCCESS = "DOOR_IMAGE_DELETE_REQUEST_SUCCESS"
export const DOOR_IMAGE_DELETE_REQUEST_ERROR = "DOOR_IMAGE_DELETE_REQUEST_ERROR"
