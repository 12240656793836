<template>
    <component :is="layout" id="app">
        <router-view />
    </component>
</template>

<script>
export default {
    name: "App",
    computed: {
        layout() {
            return `${this.$route.meta.layout || "empty"}-layout`
        }
    },
    metaInfo: {
        title: "Home",
        titleTemplate: "%s | All Doors"
    }
}
</script>
