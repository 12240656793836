// VENDORS
import Buefy from "buefy"
import VueCookies from "vue-cookies"
import { ValidationObserver, ValidationProvider } from "vee-validate"
// Icons
import { library } from "@fortawesome/fontawesome-svg-core"
import {
    faSquare as fasSquare,
    faUserCircle as fasUserCircle,
    faCheckCircle as fasCheckCircle,
    faTimesCircle as fasTimesCircle,
    faDoorOpen as fasDoorOpen,
    faKey as fasKey,
    faParking as fasParking,
    faHistory as fasHistory,
    faFileSignature as fasFileSignature,
    faIdBadge as fasIdBadge,
    faBars as fasBars,
    faArrowUp as fasArrowUp,
    faAngleLeft as fasAngleLeft,
    faAngleRight as fasAngleRight,
    faMinus as fasMinus,
    faPlus as fasPlus,
    faCheck as fasCheck,
    faExclamationCircle as fasExclamationCircle,
    faCogs as fasCogs,
    faPenSquare as fasPenSquare,
    faShareAlt as fasShareAlt,
    faShareAltSquare as fasShareAltSquare,
    faUserClock as fasUserClock,
    faBorderAll as fasBorderAll,
    faClipboardList as fasClipboardList,
    faMicrochip as fasMicrochip,
    faCaretRight as fasCaretRight,
    faPowerOff as fasPowerOff
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

// VUE APP
import Vue from "vue"
import VueMeta from "vue-meta"
import App from "./App.vue"
import router from "./router"
import store from "./store"

// LAYOUT & STYLES
import DefaultLayout from "./layouts/Default.vue"
import EmptyLayout from "./layouts/Empty.vue"
import "./styles.scss"

// CUSTOMS
import "./utils/VeeValidateExtend"
import i18n from "./i18n"

// MOCK API SERVER
// import { makeServer } from "./server"

// if (process.env.NODE_ENV === "development") {
//     makeServer()
// }

// BUILD
Vue.component("default-layout", DefaultLayout)
Vue.component("empty-layout", EmptyLayout)
library.add(
    fasSquare,
    fasUserCircle,
    fasDoorOpen,
    fasKey,
    fasCheckCircle,
    fasTimesCircle,
    fasParking,
    fasHistory,
    fasFileSignature,
    fasIdBadge,
    fasBars,
    fasArrowUp,
    fasAngleLeft,
    fasAngleRight,
    fasMinus,
    fasPlus,
    fasCheck,
    fasExclamationCircle,
    fasCogs,
    fasPenSquare,
    fasShareAlt,
    fasShareAltSquare,
    fasUserClock,
    fasBorderAll,
    fasClipboardList,
    fasMicrochip,
    fasCaretRight,
    fasPowerOff
)
Vue.component("font-awesome-icon", FontAwesomeIcon)
Vue.use(Buefy, {
    defaultIconComponent: "font-awesome-icon",
    defaultIconPack: "fas"
})
Vue.use(VueCookies)
Vue.use(VueMeta)
Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
}).$mount("#app")
