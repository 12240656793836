<template>
    <multiselect
        v-model="Language"
        :options="Languages"
        :allow-empty="false"
        placeholder=""
        select-label=""
        selected-label=""
        deselect-label=""
        track-by="code"
        label="code"
    >
        <template slot="singleLabel" slot-scope="props">
            <span
                class="flag-icon option-icon"
                :class="`flag-icon-${props.option.code.substr(3, 2).toLowerCase()}`"
            />
            <span class="option-title">{{ props.option.name }}</span>
        </template>
        <template slot="option" slot-scope="props">
            <span
                class="flag-icon option-icon"
                :class="`flag-icon-${props.option.code.substr(3, 2).toLowerCase()}`"
            />
            <span class="option-title">{{ props.option.name }}</span>
        </template>
    </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect"
import Helpers from "@/utils/Helpers"
import { LANGUAGE_SET } from "@/store/actions/language"

export default {
    name: "LanguageSelector",
    components: {
        Multiselect
    },
    computed: {
        Languages: {
            get() {
                return this.$store.state.Language.Languages
            }
        },
        Language: {
            get() {
                return this.$store.state.Language.CurrentLanguage
            },
            set(value) {
                this.$store.commit(LANGUAGE_SET, value)
            }
        }
    },
    created() {
        this.Language = this.$store.getters.GET_LANGUAGE_BY_SHORTCODE(Helpers.getCurrentLanguage())
    }
}
</script>
