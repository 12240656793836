// eslint-disable-next-line no-unused-vars
import VueCookies from "vue-cookies"
import { ToastProgrammatic as Toast } from "buefy"

const Helpers = {

    notify(type = "is-dark", message = "", duration = 5000, position = "is-top") {
        Toast.open({
            duration, message, position, type
        })
    },

    checkToken(value) {
        if (window.$cookies.get("ExpirationDate")) {
            const expire = new Date(window.$cookies.get("ExpirationDate"))
            const now = new Date()
            if (now.getTime() < expire.getTime()) {
                if (value === "AuthToken") return window.$cookies.get("AuthToken")
                if (value === "ExpirationDate") return window.$cookies.get("ExpirationDate")
                return null
            }

            window.$cookies.remove("AuthToken")
            window.$cookies.remove("ExpirationDate")
            return null
        }
        return null
    },

    redirectToGotoLoginApp(mode, backURL) {
        if (mode) {
            const GotoLoginApp = process.env.VUE_APP_GOTOLOGINAPP_URL
            const AppId = 2
            window.location.href = backURL
                ? `${GotoLoginApp}?AppId=${AppId}&Mode=${mode}&Redirect=${backURL}`
                : `${GotoLoginApp}?AppId=${AppId}&Mode=${mode}`
        }
    },

    getCurrentLanguage() {
        if (window.$cookies.isKey("Language") && window.$cookies.get("Language").code) {
            return window.$cookies.get("Language").code.substr(0, 2)
        }
        if (navigator.language) {
            return navigator.language.substr(0, 2)
        }
        return process.env.VUE_APP_I18N_LOCALE
    },

    filterDateTime(date) {
        const DefaultDate = new Date(date)
        const options = {
            hour12: false,
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        }
        return Intl.DateTimeFormat(this.getCurrentLanguage(), options).format(DefaultDate)
    },

    isObjectEmpty(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object
    }

}

export default Helpers
