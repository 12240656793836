import HTTP from "@/http"
import Helpers from "@/utils/Helpers"
import i18n from "@/i18n"
import {
    INN_REQUEST,
    INN_REQUEST_SUCCESS,
    INN_REQUEST_ERROR,
    BIC_REQUEST,
    BIC_REQUEST_SUCCESS,
    BIC_REQUEST_ERROR,

    COMPANY_INFO_REQUEST,
    COMPANY_INFO_REQUEST_SUCCESS,
    COMPANY_INFO_REQUEST_ERROR,
    COMPANY_CREATE_REQUEST,
    COMPANY_CREATE_REQUEST_SUCCESS,
    COMPANY_CREATE_REQUEST_ERROR,

    CONTRACT_INFO_REQUEST,
    CONTRACT_INFO_REQUEST_SUCCESS,
    CONTRACT_INFO_REQUEST_ERROR
} from "../actions/contract"

const state = {
    Status: null,
    Accepted: false,
    Company: {
        Id: null,
        Country: null,
        FullName: null,
        ShortName: null,
        Registered: null,
        Ogrn: null,
        Inn: null,
        Kpp: null,
        Address: null,
        ManagerPosition: null,
        ManagerName: null,
        ManagerCredentials: null,
        BankName: null,
        BankCity: null,
        Bic: null,
        CorrespondentBankAccount: null,
        BankAccount: null,
        Type: null
    }
}

const getters = {
    IS_COMPANY_CREATED: (state) => !!state.Company.Id,
    IS_CONTRACT_ACCEPTED: (state) => !!state.Accepted
}

const actions = {
    [INN_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(INN_REQUEST)
        HTTP({
            url: `company/search/${payload}`,
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.data[0].FullName) {
                    commit(INN_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(INN_REQUEST_ERROR, error)
            })
    }),
    [BIC_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(BIC_REQUEST)
        HTTP({
            url: `bank/search/${payload}`,
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.data.Name) {
                    commit(BIC_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(BIC_REQUEST_ERROR, error)
            })
    }),

    [COMPANY_INFO_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
        commit(COMPANY_INFO_REQUEST)
        HTTP({
            url: "company",
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if ((response && response.data && response.data.Id)
                    || (response && response.status && response.status === 204)) {
                    commit(COMPANY_INFO_REQUEST_SUCCESS, response.data)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(COMPANY_INFO_REQUEST_ERROR, error)
            })
    }),
    [COMPANY_CREATE_REQUEST]: ({ commit }, form) => new Promise((resolve, reject) => {
        commit(COMPANY_CREATE_REQUEST)
        HTTP({
            url: "company",
            data: form,
            method: "POST"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.data.Id) {
                    commit(COMPANY_CREATE_REQUEST_SUCCESS, response.data)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(COMPANY_CREATE_REQUEST_ERROR, error)
            })
    }),

    [CONTRACT_INFO_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
        commit(CONTRACT_INFO_REQUEST)
        HTTP({
            url: "contract",
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data) {
                    commit(CONTRACT_INFO_REQUEST_SUCCESS, response.data)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(CONTRACT_INFO_REQUEST_ERROR, error)
            })
    })
}

const mutations = {
    [INN_REQUEST]: (state) => {
        state.Status = "company info request"
    },
    [INN_REQUEST_SUCCESS]: (state) => {
        state.Status = "success"
    },
    [INN_REQUEST_ERROR]: (state, payload) => {
        state.Status = "error"
        if (payload && payload.response && payload.response.data && payload.response.data.Error) {
            Helpers.notify("is-danger", payload.response.data.Error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [BIC_REQUEST]: (state) => {
        state.Status = "bank info request"
    },
    [BIC_REQUEST_SUCCESS]: (state) => {
        state.Status = "success"
    },
    [BIC_REQUEST_ERROR]: (state, payload) => {
        state.Status = "error"
        if (payload && payload.response && payload.response.data && payload.response.data.Error) {
            Helpers.notify("is-danger", payload.response.data.Error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },

    [COMPANY_INFO_REQUEST]: (state) => {
        state.Status = "company is downloading"
    },
    [COMPANY_INFO_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "company info is loaded"
        state.Company.Id = payload.Id
        state.Company.Country = payload.Country
        state.Company.FullName = payload.FullName
        state.Company.ShortName = payload.ShortName
        state.Company.Registered = payload.Registered
        state.Company.Ogrn = payload.Ogrn
        state.Company.Inn = payload.Inn
        state.Company.Kpp = payload.Kpp
        state.Company.Address = payload.Address
        state.Company.ManagerPosition = payload.ManagerPosition
        state.Company.ManagerName = payload.ManagerName
        state.Company.ManagerCredentials = payload.ManagerCredentials
        state.Company.BankName = payload.BankName
        state.Company.BankCity = payload.BankCity
        state.Company.Bic = payload.Bic
        state.Company.CorrespondentBankAccount = payload.CorrespondentBankAccount
        state.Company.BankAccount = payload.BankAccount
        state.Company.Type = payload.Type
    },
    [COMPANY_INFO_REQUEST_ERROR]: (state, payload) => {
        state.Status = "company get info error"
        if (payload && payload.response && payload.response.data && payload.response.data.Error) {
            Helpers.notify("is-danger", payload.response.data.Error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [COMPANY_CREATE_REQUEST]: (state) => {
        state.Status = "contract is uploading"
    },
    [COMPANY_CREATE_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "company created"
        state.Company.Id = payload.Id
        state.Company.Country = payload.Country
        state.Company.FullName = payload.FullName
        state.Company.ShortName = payload.ShortName
        state.Company.Registered = payload.Registered
        state.Company.Ogrn = payload.Ogrn
        state.Company.Inn = payload.Inn
        state.Company.Kpp = payload.Kpp
        state.Company.Address = payload.Address
        state.Company.ManagerPosition = payload.ManagerPosition
        state.Company.ManagerName = payload.ManagerName
        state.Company.ManagerCredentials = payload.ManagerCredentials
        state.Company.BankName = payload.BankName
        state.Company.BankCity = payload.BankCity
        state.Company.Bic = payload.Bic
        state.Company.CorrespondentBankAccount = payload.CorrespondentBankAccount
        state.Company.BankAccount = payload.BankAccount
        state.Company.Type = payload.Type
    },
    [COMPANY_CREATE_REQUEST_ERROR]: (state, payload) => {
        state.Status = "company create error"
        if (payload && payload.response && payload.response.data && payload.response.data.Error) {
            Helpers.notify("is-danger", payload.response.data.Error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },

    [CONTRACT_INFO_REQUEST]: (state) => {
        state.Status = "contract info is downloading"
    },
    [CONTRACT_INFO_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "contract info is loaded"
        state.Accepted = payload.paid
    },
    [CONTRACT_INFO_REQUEST_ERROR]: (state, payload) => {
        state.Status = "contract get info error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
