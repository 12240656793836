<template>
    <b-tooltip
        :label="value ? hintTrue : hintFalse"
        :position="hintPosition"
        :type="hintColor"
        animated
    >
        <font-awesome-icon
            :icon="['fas', value ? 'check-circle' : 'times-circle']"
            :class="[ClassTrue, ClassFalse, ClassSize]"
        />
    </b-tooltip>
</template>

<script>
export default {
    name: "IconBoolean",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: ""
        },
        classTrue: {
            type: String,
            default: "has-text-success"
        },
        classFalse: {
            type: String,
            default: "has-text-danger"
        },
        hintTrue: {
            type: String,
            default: "It's true"
        },
        hintFalse: {
            type: String,
            default: "It's false"
        },
        hintPosition: {
            type: String,
            default: "is-left"
        },
        hintColor: {
            type: String,
            default: "is-dark"
        }
    },
    computed: {
        ClassTrue() {
            return this.value ? this.classTrue : null
        },
        ClassFalse() {
            return !this.value ? this.classFalse : null
        },
        ClassSize() {
            return this.size
        }
    }
}
</script>
