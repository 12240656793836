import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
    {
        name: "Welcome",
        path: "/",
        meta: { layout: "empty" },
        component: () => import("./views/Welcome.vue")
    },
    {
        name: "Dashboard",
        path: "/dashboard",
        meta: { layout: "default" },
        component: () => import("./views/Dashboard.vue")
    },
    {
        name: "Door-List",
        path: "/doors",
        meta: { layout: "default" },
        component: () => import("./views/Door/List.vue")
    },
    {
        name: "Door-Edit",
        path: "/door/edit/:id",
        meta: { layout: "default" },
        component: () => import("./views/Door/Edit.vue")
    },
    {
        name: "Door-Delete",
        path: "/door/delete/:id",
        meta: { layout: "default" },
        component: () => import("./views/Door/Delete.vue")
    },
    {
        name: "Key-List",
        path: "/keys",
        meta: { layout: "default" },
        component: () => import("./views/Key/List.vue")
    },
    {
        name: "Key-Create",
        path: "/key/create",
        meta: { layout: "default" },
        component: () => import("./views/Key/CreateEdit.vue")
    },
    {
        name: "Key-Edit",
        path: "/key/edit/:id",
        meta: { layout: "default" },
        component: () => import("./views/Key/CreateEdit.vue")
    },
    {
        name: "Key-Delete",
        path: "/key/delete/:id",
        meta: { layout: "default" },
        component: () => import("./views/Key/Delete.vue")
    },
    {
        name: "Key-Invites",
        path: "/key/invites/:id",
        meta: { layout: "default" },
        component: () => import("./views/Key/Invites.vue")
    },
    {
        name: "Key-Share",
        path: "/key/share/:id",
        meta: { layout: "default" },
        component: () => import("./views/Key/Share.vue")
    },
    {
        name: "Log-List",
        path: "/logs",
        meta: { layout: "default" },
        component: () => import("./views/Log/List.vue")
    },
    {
        name: "About",
        path: "/about",
        meta: { layout: "default" },
        component: () => import("./views/About.vue")
    },
    {
        name: "Account",
        path: "/account",
        meta: { layout: "default" },
        component: () => import("./views/Settings/Account.vue")
    },
    {
        name: "DoorProvider-List",
        path: "/doorproviders",
        meta: { layout: "default" },
        component: () => import("./views/DoorProvider/List.vue")
    },
    {
        name: "DoorProvider-Add",
        path: "/doorprovider/add",
        meta: { layout: "default" },
        component: () => import("./views/DoorProvider/AddEdit.vue")
    },
    {
        name: "DoorProvider-Edit",
        path: "/doorprovider/edit/:id",
        meta: { layout: "default" },
        component: () => import("./views/DoorProvider/AddEdit.vue")
    },
    {
        name: "DoorProvider-Delete",
        path: "/doorprovider/delete/:id",
        meta: { layout: "default" },
        component: () => import("./views/DoorProvider/Delete.vue")
    },
    {
        name: "IronLogic-List",
        path: "/ironlogiccontrollers",
        meta: { layout: "default" },
        component: () => import("./views/IronLogic/List.vue")
    },
    {
        name: "IronLogic-Add",
        path: "/ironlogiccontroller/add",
        meta: { layout: "default" },
        component: () => import("./views/IronLogic/AddEdit.vue")
    },
    {
        name: "IronLogic-Edit",
        path: "/ironlogiccontroller/edit/:id",
        meta: { layout: "default" },
        component: () => import("./views/IronLogic/AddEdit.vue")
    },
    {
        name: "IronLogic-Delete",
        path: "/ironlogiccontroller/delete/:id",
        meta: { layout: "default" },
        component: () => import("./views/IronLogic/Delete.vue")
    },
    {
        name: "Contract",
        path: "/contract/details",
        meta: { layout: "default" },
        component: () => import("./views/Contract/Details.vue")
    },
    {
        name: "Contract-Create-ru",
        path: "/contract/create/ru",
        meta: { layout: "default" },
        component: () => import("./views/Contract/Create/Russia.vue")
    },
    {
        name: "Contract-File-ru-owner",
        path: "/contract/file/AllDoors_agent_services_public_offer_rus",
        meta: { layout: "empty" },
        component: () => import("./views/Contract/File/RussiaOwner.vue")
    },
    {
        name: "Contract-File-ru-agent",
        path: "/contract/file/AllDoors_agent_services_public_offer_rus_agent",
        meta: { layout: "empty" },
        component: () => import("./views/Contract/File/RussiaAgent.vue")
    },
    {
        name: "Contract-Create-us",
        path: "/contract/create/us",
        meta: { layout: "default" },
        component: () => import("./views/Contract/Create/USA.vue")
    },
    {
        name: "Contract-File-us-owner",
        path: "/contract/file/AllDoors_agent_services_public_offer_us",
        meta: { layout: "empty" },
        component: () => import("./views/Contract/File/UsaOwner.vue")
    },
    {
        name: "Contract-File-us-agent",
        path: "/contract/file/AllDoors_agent_services_public_offer_us_agent",
        meta: { layout: "empty" },
        component: () => import("./views/Contract/File/UsaAgent.vue")
    },
    {
        name: "Payment-Yandex-Widget",
        path: "/payment/yandex/pay",
        meta: { layout: "default" },
        component: () => import("./views/Payment/Yandex/Widget.vue")
    },
    {
        name: "Payment-Yandex-Success",
        path: "/payment/yandex/success",
        meta: { layout: "default" },
        component: () => import("./views/Payment/Yandex/Success.vue")
    },
    {
        name: "Login",
        path: "/login",
        meta: { layout: "empty" },
        component: () => import("./views/Login.vue")
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

export default router
