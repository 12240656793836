// eslint-disable-next-line no-unused-vars
import VueCookies from "vue-cookies"
import HTTP from "@/http"
import i18n from "@/i18n"
import { LANGUAGE_SET } from "../actions/language"

const state = {
    Languages: [
        {
            code: "en-US",
            name: "English"
        },
        {
            code: "es-ES",
            name: "Español"
        },
        {
            code: "de-DE",
            name: "Deutsch"
        },
        {
            code: "it-IT",
            name: "L'italiano"
        },
        {
            code: "fr-FR",
            name: "Français"
        },
        {
            code: "ru-RU",
            name: "Русский"
        }
    ],
    CurrentLanguage: null
}

const getters = {
    CURRENT_LANGUAGE: (state) => state.CurrentLanguage,
    GET_LANGUAGE_BY_SHORTCODE: (state) => (code) => state.Languages.find((x) => x.code.substr(0, 2) === code)
}

const mutations = {
    [LANGUAGE_SET]: (state, payload) => {
        state.CurrentLanguage = payload
        i18n.locale = payload.code.substr(0, 2)
        HTTP.defaults.headers.common["Accept-Language"] = payload.code.substr(0, 2)

        const ExpireDate = new Date()
        ExpireDate.setDate(ExpireDate.getDate() + 30)
        window.$cookies.set("Language", payload, ExpireDate)
    }
}

export default {
    state,
    getters,
    mutations
}
