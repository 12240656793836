<template>
    <div id="layout-default">
        <Navbar />
        <Sidebar />
        <div class="appcontent">
            <transition name="fade" mode="out-in">
                <slot />
            </transition>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/Layout/Navbar"
import Sidebar from "@/components/Layout/Sidebar"
import { LOGIN_REQUEST } from "@/store/actions/auth"

export default {
    components: {
        Navbar,
        Sidebar
    },
    mounted() {
        if (
            !this.$store.getters.IS_AUTHENTICATED
            && this.$route
            && this.$route.query
            && this.$route.query.AuthToken
            && this.$route.query.ExpirationDate
        ) { this.$store.dispatch(LOGIN_REQUEST) }
    }
}
</script>
