export const IRONLOGIC_CONTROLLER_LIST_REQUEST = "IRONLOGIC_CONTROLLER_LIST_REQUEST"
export const IRONLOGIC_CONTROLLER_LIST_REQUEST_SUCCESS = "IRONLOGIC_CONTROLLER_LIST_REQUEST_SUCCESS"
export const IRONLOGIC_CONTROLLER_LIST_REQUEST_ERROR = "IRONLOGIC_CONTROLLER_LIST_REQUEST_ERROR"

export const IRONLOGIC_CONTROLLER_GET_REQUEST = "IRONLOGIC_CONTROLLER_GET_REQUEST"
export const IRONLOGIC_CONTROLLER_GET_REQUEST_SUCCESS = "IRONLOGIC_CONTROLLER_GET_REQUEST_SUCCESS"
export const IRONLOGIC_CONTROLLER_GET_REQUEST_ERROR = "IRONLOGIC_CONTROLLER_GET_REQUEST_ERROR"

export const IRONLOGIC_CONTROLLER_ADD_REQUEST = "IRONLOGIC_CONTROLLER_ADD_REQUEST"
export const IRONLOGIC_CONTROLLER_ADD_REQUEST_SUCCESS = "IRONLOGIC_CONTROLLER_ADD_REQUEST_SUCCESS"
export const IRONLOGIC_CONTROLLER_ADD_REQUEST_ERROR = "IRONLOGIC_CONTROLLER_ADD_REQUEST_ERROR"

export const IRONLOGIC_CONTROLLER_EDIT_REQUEST = "IRONLOGIC_CONTROLLER_EDIT_REQUEST"
export const IRONLOGIC_CONTROLLER_EDIT_REQUEST_SUCCESS = "IRONLOGIC_CONTROLLER_EDIT_REQUEST_SUCCESS"
export const IRONLOGIC_CONTROLLER_EDIT_REQUEST_ERROR = "IRONLOGIC_CONTROLLER_EDIT_REQUEST_ERROR"

export const IRONLOGIC_CONTROLLER_DELETE_REQUEST = "IRONLOGIC_CONTROLLER_DELETE_REQUEST"
export const IRONLOGIC_CONTROLLER_DELETE_REQUEST_SUCCESS = "IRONLOGIC_CONTROLLER_DELETE_REQUEST_SUCCESS"
export const IRONLOGIC_CONTROLLER_DELETE_REQUEST_ERROR = "IRONLOGIC_CONTROLLER_DELETE_REQUEST_ERROR"

export const IRONLOGIC_GENERATE_API_KEY_REQUEST = "IRONLOGIC_GENERATE_API_KEY_REQUEST"
export const IRONLOGIC_GENERATE_API_KEY_REQUEST_SUCCESS = "IRONLOGIC_GENERATE_API_KEY_REQUEST_SUCCESS"
export const IRONLOGIC_GENERATE_API_KEY_REQUEST_ERROR = "IRONLOGIC_GENERATE_API_KEY_REQUEST_ERROR"
