import HTTP from "@/http"
import Helpers from "@/utils/Helpers"
import i18n from "@/i18n"
import {
    SYSTEM_STATUS_REQUEST,
    SYSTEM_STATUS_REQUEST_ERROR,
    SYSTEM_STATUS_REQUEST_SUCCESS
} from "../actions/system"

const state = {
    Status: "",
    Loaded: false,
    AtLeastOneDoorProviderBound: null,
    AtLeastOneDoorAvailable: false,
    AtLeastOneKeyCreated: false,
    ContractConcluded: false,
    IronLogicAdded: false
}

const getters = {
    IS_SYSTEM_STATUS_LOADED: (state) => state.Loaded,
    IS_DOOR_PROVIDER_BOUNDED: (state) => state.AtLeastOneDoorProviderBound,
    IS_AT_LEAST_ONE_DOOR_AVAILABLE: (state) => state.AtLeastOneDoorAvailable,
    IS_AT_LEAST_ONE_KEY_CREATED: (state) => state.AtLeastOneKeyCreated,
    IS_CONTRACT_CONCLUDED: (state) => state.ContractConcluded,
    IS_DOORPROVIDER_IRONLOGIC_ADDED: (state) => state.IronLogicAdded
}

const actions = {
    [SYSTEM_STATUS_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
        commit(SYSTEM_STATUS_REQUEST)
        HTTP({
            url: "status",
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data) {
                    commit(SYSTEM_STATUS_REQUEST_SUCCESS, response.data)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(SYSTEM_STATUS_REQUEST_ERROR, error)
            })
    })
}

const mutations = {
    [SYSTEM_STATUS_REQUEST]: (state) => {
        state.Status = "loading"
    },
    [SYSTEM_STATUS_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "success"
        state.Loaded = true
        state.AtLeastOneDoorProviderBound = payload.hasDoorProvider
        state.AtLeastOneDoorAvailable = payload.hasDoor
        state.AtLeastOneKeyCreated = payload.hasKey
        state.ContractConcluded = payload.contractAccepted
        state.IronLogicAdded = payload.ironLogicAdded
    },
    [SYSTEM_STATUS_REQUEST_ERROR]: (state, payload) => {
        state.Status = "error"
        if (
            payload
            && payload.response
            && payload.response.data
            && payload.response.data.error
        ) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
