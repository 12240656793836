import HTTP from "@/http"
import Helpers from "@/utils/Helpers"
import i18n from "@/i18n"
import {
    IRONLOGIC_CONTROLLER_LIST_REQUEST,
    IRONLOGIC_CONTROLLER_LIST_REQUEST_SUCCESS,
    IRONLOGIC_CONTROLLER_LIST_REQUEST_ERROR,
    IRONLOGIC_CONTROLLER_GET_REQUEST,
    IRONLOGIC_CONTROLLER_GET_REQUEST_SUCCESS,
    IRONLOGIC_CONTROLLER_GET_REQUEST_ERROR,
    IRONLOGIC_CONTROLLER_ADD_REQUEST,
    IRONLOGIC_CONTROLLER_ADD_REQUEST_SUCCESS,
    IRONLOGIC_CONTROLLER_ADD_REQUEST_ERROR,
    IRONLOGIC_CONTROLLER_EDIT_REQUEST,
    IRONLOGIC_CONTROLLER_EDIT_REQUEST_SUCCESS,
    IRONLOGIC_CONTROLLER_EDIT_REQUEST_ERROR,
    IRONLOGIC_CONTROLLER_DELETE_REQUEST,
    IRONLOGIC_CONTROLLER_DELETE_REQUEST_SUCCESS,
    IRONLOGIC_CONTROLLER_DELETE_REQUEST_ERROR,
    IRONLOGIC_GENERATE_API_KEY_REQUEST,
    IRONLOGIC_GENERATE_API_KEY_REQUEST_SUCCESS,
    IRONLOGIC_GENERATE_API_KEY_REQUEST_ERROR
} from "../actions/ironlogic"

const state = {
    Status: null,
    List: [],
    ApiKey: null
}

const getters = {
    IS_IRONLOGIC_CONTROLLERS_LOADED: (state) => !!(
        state.List
        && typeof state.List !== "undefined"
        && state.List.length > 0
    ),
    IRONLOGIC_CONTROLLERS_AMOUNT: (state) => state.List.length
}

const actions = {
    [IRONLOGIC_CONTROLLER_LIST_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
        commit(IRONLOGIC_CONTROLLER_LIST_REQUEST)
        HTTP({
            url: `${process.env.VUE_APP_API_IRONLOGIC_URL}controller`,
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.status === 200) {
                    commit(IRONLOGIC_CONTROLLER_LIST_REQUEST_SUCCESS, response.data)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(IRONLOGIC_CONTROLLER_LIST_REQUEST_ERROR, error)
            })
    }),
    [IRONLOGIC_CONTROLLER_GET_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(IRONLOGIC_CONTROLLER_GET_REQUEST)
        HTTP({
            url: `${process.env.VUE_APP_API_IRONLOGIC_URL}controller/${payload}`,
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.status === 200) {
                    commit(IRONLOGIC_CONTROLLER_GET_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(IRONLOGIC_CONTROLLER_GET_REQUEST_ERROR, error)
            })
    }),
    [IRONLOGIC_CONTROLLER_ADD_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(IRONLOGIC_CONTROLLER_ADD_REQUEST)
        HTTP({
            url: `${process.env.VUE_APP_API_IRONLOGIC_URL}controller`,
            data: payload,
            method: "POST"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status === 204) {
                    commit(IRONLOGIC_CONTROLLER_ADD_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(IRONLOGIC_CONTROLLER_ADD_REQUEST_ERROR, error)
            })
    }),
    [IRONLOGIC_CONTROLLER_EDIT_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(IRONLOGIC_CONTROLLER_EDIT_REQUEST)
        HTTP({
            url: `${process.env.VUE_APP_API_IRONLOGIC_URL}controller/${payload.Id}`,
            data: payload.Form,
            method: "PUT"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status && response.status === 204) {
                    commit(IRONLOGIC_CONTROLLER_EDIT_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(IRONLOGIC_CONTROLLER_EDIT_REQUEST_ERROR, error)
            })
    }),
    [IRONLOGIC_CONTROLLER_DELETE_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(IRONLOGIC_CONTROLLER_DELETE_REQUEST)
        HTTP({
            url: `${process.env.VUE_APP_API_IRONLOGIC_URL}controller/${payload}`,
            method: "DELETE"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status && response.status === 204) {
                    commit(IRONLOGIC_CONTROLLER_DELETE_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(IRONLOGIC_CONTROLLER_DELETE_REQUEST_ERROR, error)
            })
    }),
    [IRONLOGIC_GENERATE_API_KEY_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
        commit(IRONLOGIC_GENERATE_API_KEY_REQUEST)
        HTTP({
            url: `${process.env.VUE_APP_API_IRONLOGIC_URL}apikey`,
            method: "POST"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.status === 200) {
                    commit(IRONLOGIC_GENERATE_API_KEY_REQUEST_SUCCESS, response.data.apiKey)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(IRONLOGIC_GENERATE_API_KEY_REQUEST_ERROR, error)
            })
    })
}

const mutations = {
    [IRONLOGIC_CONTROLLER_LIST_REQUEST]: (state) => {
        state.Status = "loading"
    },
    [IRONLOGIC_CONTROLLER_LIST_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "load success"
        state.List = payload
    },
    [IRONLOGIC_CONTROLLER_LIST_REQUEST_ERROR]: (state, payload) => {
        state.Status = "load error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [IRONLOGIC_CONTROLLER_GET_REQUEST]: (state) => {
        state.Status = "loading"
    },
    [IRONLOGIC_CONTROLLER_GET_REQUEST_SUCCESS]: (state) => {
        state.Status = "load success"
    },
    [IRONLOGIC_CONTROLLER_GET_REQUEST_ERROR]: (state, payload) => {
        state.Status = "load error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [IRONLOGIC_CONTROLLER_ADD_REQUEST]: (state) => {
        state.Status = "adding"
    },
    [IRONLOGIC_CONTROLLER_ADD_REQUEST_SUCCESS]: (state) => {
        state.Status = "add success"
    },
    [IRONLOGIC_CONTROLLER_ADD_REQUEST_ERROR]: (state, payload) => {
        state.Status = "add error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [IRONLOGIC_CONTROLLER_EDIT_REQUEST]: (state) => {
        state.Status = "editing"
    },
    [IRONLOGIC_CONTROLLER_EDIT_REQUEST_SUCCESS]: (state) => {
        state.Status = "edit success"
    },
    [IRONLOGIC_CONTROLLER_EDIT_REQUEST_ERROR]: (state, payload) => {
        state.Status = "edit error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [IRONLOGIC_CONTROLLER_DELETE_REQUEST]: (state) => {
        state.Status = "deleting"
    },
    [IRONLOGIC_CONTROLLER_DELETE_REQUEST_SUCCESS]: (state) => {
        state.Status = "delete success"
    },
    [IRONLOGIC_CONTROLLER_DELETE_REQUEST_ERROR]: (state, payload) => {
        state.Status = "delete error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [IRONLOGIC_GENERATE_API_KEY_REQUEST]: (state) => {
        state.Status = "generating"
    },
    [IRONLOGIC_GENERATE_API_KEY_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "generate api key success"
        state.ApiKey = payload
    },
    [IRONLOGIC_GENERATE_API_KEY_REQUEST_ERROR]: (state, payload) => {
        state.Status = "generate api key error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
