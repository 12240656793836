<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-menu">
            <div class="navbar-start">
                <div class="navbar-item">
                    <a
                        href="https://bitbucket.org/gotollc/alldoors_public_api_docs/src/master/"
                        target="_blank"
                    >
                        {{ $t("Interface.Menu.ApiLink") }}
                    </a>
                </div>
            </div>
            <div class="navbar-end">
                <div class="navbar-item">
                    <LanguageSelector />
                </div>
                <div class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link">
                        <WidgetAccount />
                    </a>
                    <div class="navbar-dropdown">
                        <a class="navbar-item" @click="logout">
                            {{ $t("Interface.Menu.Logout") }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import LanguageSelector from "@/components/Widget/LanguageSelector"
import WidgetAccount from "@/components/Widget/Account"
import { LOGOUT_REQUEST } from "@/store/actions/auth"

export default {
    name: "Navbar",
    components: {
        LanguageSelector,
        WidgetAccount
    },
    methods: {
        logout() {
            this.$store.dispatch(LOGOUT_REQUEST)
        }
    }
}
</script>
