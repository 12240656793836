export const INN_REQUEST = "INN_REQUEST"
export const INN_REQUEST_SUCCESS = "INN_REQUEST_SUCCESS"
export const INN_REQUEST_ERROR = "INN_REQUEST_ERROR"

export const BIC_REQUEST = "BIC_REQUEST"
export const BIC_REQUEST_SUCCESS = "BIC_REQUEST_SUCCESS"
export const BIC_REQUEST_ERROR = "BIC_REQUEST_ERROR"

export const COMPANY_INFO_REQUEST = "COMPANY_INFO_REQUEST"
export const COMPANY_INFO_REQUEST_SUCCESS = "COMPANY_INFO_REQUEST_SUCCESS"
export const COMPANY_INFO_REQUEST_ERROR = "COMPANY_INFO_REQUEST_ERROR"

export const COMPANY_CREATE_REQUEST = "COMPANY_CREATE_REQUEST"
export const COMPANY_CREATE_REQUEST_SUCCESS = "COMPANY_CREATE_REQUEST_SUCCESS"
export const COMPANY_CREATE_REQUEST_ERROR = "COMPANY_CREATE_REQUEST_ERROR"

export const CONTRACT_INFO_REQUEST = "CONTRACT_INFO_REQUEST"
export const CONTRACT_INFO_REQUEST_SUCCESS = "CONTRACT_INFO_REQUEST_SUCCESS"
export const CONTRACT_INFO_REQUEST_ERROR = "CONTRACT_INFO_REQUEST_ERROR"
