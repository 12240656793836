import HTTP from "@/http"
import Helpers from "@/utils/Helpers"
import i18n from "@/i18n"
import {
    LOG_LIST_REQUEST,
    LOG_LIST_REQUEST_SUCCESS,
    LOG_LIST_REQUEST_ERROR
} from "../actions/log"

const state = {
    Status: null,
    List: []
}

const getters = {
    IS_LOGS_LOADED: (state) => !!(
        state.List
        && typeof state.List !== "undefined"
        && state.List.length > 0
    ),
    LOGS_AMOUNT: (state) => state.List.length
}

const actions = {
    [LOG_LIST_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
        commit(LOG_LIST_REQUEST)
        HTTP({
            url: "logs",
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.status === 200) {
                    commit(LOG_LIST_REQUEST_SUCCESS, response.data)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(LOG_LIST_REQUEST_ERROR, error)
            })
    })
}

const mutations = {
    [LOG_LIST_REQUEST]: (state) => {
        state.Status = "loading"
    },
    [LOG_LIST_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "success"
        state.List = payload
    },
    [LOG_LIST_REQUEST_ERROR]: (state, payload) => {
        state.Status = "error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
