import HTTP from "@/http"
import Helpers from "@/utils/Helpers"
import i18n from "@/i18n"
import {
    PAYMENT_YANDEX_TOKEN_REQUEST,
    PAYMENT_YANDEX_TOKEN_REQUEST_SUCCESS,
    PAYMENT_YANDEX_TOKEN_REQUEST_ERROR
} from "../actions/payment"

const state = {
    Status: null,
    ConfirmationToken: null,
    TokenCreatedAt: null
}

const getters = {
    IS_YANDEX_PAYMENT_CREATED: (state) => !!state.ConfirmationToken
}

const actions = {
    [PAYMENT_YANDEX_TOKEN_REQUEST]: ({ commit, rootState }, successurl) => new Promise((resolve, reject) => {
        const payload = Object.assign(rootState.Account, rootState.Contract.Company)
        delete payload.Status
        delete payload.Name
        delete payload.IsPhoneVerified
        delete payload.IsEmailConfirmed
        payload.SuccessUrl = successurl
        commit(PAYMENT_YANDEX_TOKEN_REQUEST)
        HTTP({
            url: "contract",
            data: payload,
            method: "POST"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status && response.status === 200 && response.data && response.data.confirmation_token) {
                    commit(PAYMENT_YANDEX_TOKEN_REQUEST_SUCCESS, response.data)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(PAYMENT_YANDEX_TOKEN_REQUEST_ERROR, error)
            })
    })
}

const mutations = {
    [PAYMENT_YANDEX_TOKEN_REQUEST]: (state) => {
        state.Status = "create payment requested"
    },
    [PAYMENT_YANDEX_TOKEN_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "success"
        state.ConfirmationToken = payload.confirmation_token
        state.TokenCreatedAt = payload.created_at
    },
    [PAYMENT_YANDEX_TOKEN_REQUEST_ERROR]: (state, payload) => {
        state.Status = "error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
