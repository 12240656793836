import HTTP from "@/http"
import Helpers from "@/utils/Helpers"
// import { LOGOUT_REQUEST } from "../actions/auth"
import i18n from "@/i18n"
import {
    ACCOUNT_REQUEST,
    ACCOUNT_REQUEST_ERROR,
    ACCOUNT_REQUEST_SUCCESS,
    ACCOUNT_CHANGE_NAME,
    ACCOUNT_CHANGE_EMAIL,
    ACCOUNT_CHANGE_PHONE,
    ACCOUNT_UPDATE,
    ACCOUNT_UPDATE_ERROR,
    ACCOUNT_UPDATE_SUCCESS
} from "../actions/account"

const state = {
    Status: "",
    Name: null,
    Email: null,
    Phone: null,
    IsPhoneVerified: false,
    IsEmailConfirmed: false
}

const getters = {
    IS_SESSION_READY: (state) => !!state.Email && !!state.Phone && !!state.Name,
    IS_EMAIL_CONFIRMED: (state) => state.IsEmailConfirmed,
    IS_PHONE_VERIFIED: (state) => state.IsPhoneVerified
}

const actions = {
    [ACCOUNT_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
        commit(ACCOUNT_REQUEST)
        HTTP({
            url: "account",
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.data.Name) {
                    commit(ACCOUNT_REQUEST_SUCCESS, response.data)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(ACCOUNT_REQUEST_ERROR, error)
                // dispatch(LOGOUT_REQUEST)
            })
    }),
    [ACCOUNT_UPDATE]: ({ commit }, form) => new Promise((resolve, reject) => {
        commit(ACCOUNT_UPDATE)
        HTTP({
            url: "account",
            data: form,
            method: "PUT"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.data.Id) {
                    commit(ACCOUNT_UPDATE_SUCCESS, response.data)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(ACCOUNT_UPDATE_ERROR, error)
            })
    })
}

const mutations = {
    [ACCOUNT_REQUEST]: (state) => {
        state.Status = "loading"
    },
    [ACCOUNT_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "success"
        state.Name = payload.Name
        state.Email = payload.Email
        state.Phone = payload.Phone
        state.IsEmailConfirmed = payload.IsEmailConfirmed
        state.IsPhoneVerified = payload.IsPhoneVerified
    },
    [ACCOUNT_REQUEST_ERROR]: (state, payload) => {
        state.Status = "error"
        if (
            payload
            && payload.response
            && payload.response.data
            && payload.response.data.Error
        ) {
            Helpers.notify("is-danger", payload.response.data.Error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [ACCOUNT_CHANGE_NAME]: (state, payload) => {
        state.Name = payload
    },
    [ACCOUNT_CHANGE_EMAIL]: (state, payload) => {
        state.Email = payload
    },
    [ACCOUNT_CHANGE_PHONE]: (state, payload) => {
        state.Phone = payload
    },
    // [SESSION_LOGOUT]: state => {
    //     state.Status = ""
    //     state.Name = null
    //     state.Email = null
    //     state.Phone = null
    //     state.IsPhoneVerified = false
    //     state.IsEmailConfirmed = false
    // },
    [ACCOUNT_UPDATE]: (state) => {
        state.Status = "updating"
    },
    [ACCOUNT_UPDATE_SUCCESS]: (state, payload) => {
        state.Status = "success"
        state.Name = payload.Name
        state.Email = payload.Email
        state.Phone = payload.Phone
        state.IsEmailConfirmed = payload.IsEmailConfirmed
        state.IsPhoneVerified = payload.IsPhoneVerified
    },
    [ACCOUNT_UPDATE_ERROR]: (state, payload) => {
        state.Status = "error"
        if (payload && payload.response && payload.response.data && payload.response.data.Error) {
            Helpers.notify("is-danger", payload.response.data.Error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
