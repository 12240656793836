import Vue from "vue"
import VueI18n from "vue-i18n"
import Helpers from "@/utils/Helpers"
import en from "vee-validate/dist/locale/en"
import de from "vee-validate/dist/locale/de"
import es from "vee-validate/dist/locale/es"
import fr from "vee-validate/dist/locale/fr"
import it from "vee-validate/dist/locale/it"
import ru from "vee-validate/dist/locale/ru"

Vue.use(VueI18n)

function loadLocaleMessages() {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
            switch (locale) {
            case "de":
                messages[locale].validation = de.messages
                break
            case "es":
                messages[locale].validation = es.messages
                break
            case "fr":
                messages[locale].validation = fr.messages
                break
            case "it":
                messages[locale].validation = it.messages
                break
            case "ru":
                messages[locale].validation = ru.messages
                break
            default:
                messages[locale].validation = en.messages
                break
            }
        }
    })
    return messages
}

export default new VueI18n({
    locale: Helpers.getCurrentLanguage() || "en",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: loadLocaleMessages()
})
