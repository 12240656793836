export const ACCOUNT_REQUEST = "ACCOUNT_REQUEST"
export const ACCOUNT_REQUEST_SUCCESS = "ACCOUNT_REQUEST_SUCCESS"
export const ACCOUNT_REQUEST_ERROR = "ACCOUNT_REQUEST_ERROR"

export const ACCOUNT_CHANGE_NAME = "ACCOUNT_CHANGE_NAME"
export const ACCOUNT_CHANGE_EMAIL = "ACCOUNT_CHANGE_EMAIL"
export const ACCOUNT_CHANGE_PHONE = "ACCOUNT_CHANGE_PHONE"

export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE"
export const ACCOUNT_UPDATE_SUCCESS = "ACCOUNT_UPDATE_SUCCESS"
export const ACCOUNT_UPDATE_ERROR = "ACCOUNT_UPDATE_ERROR"
