import HTTP from "@/http"
import Helpers from "@/utils/Helpers"
import i18n from "@/i18n"
import {
    DOOR_LIST_REQUEST,
    DOOR_LIST_REQUEST_ERROR,
    DOOR_LIST_REQUEST_SUCCESS,
    DOOR_EDIT_REQUEST,
    DOOR_EDIT_REQUEST_ERROR,
    DOOR_EDIT_REQUEST_SUCCESS,
    DOOR_UPDATE_REQUEST,
    DOOR_UPDATE_REQUEST_ERROR,
    DOOR_UPDATE_REQUEST_SUCCESS,
    DOOR_DELETE_REQUEST,
    DOOR_DELETE_REQUEST_ERROR,
    DOOR_DELETE_REQUEST_SUCCESS,
    DOOR_IMAGE_DELETE_REQUEST,
    DOOR_IMAGE_DELETE_REQUEST_ERROR,
    DOOR_IMAGE_DELETE_REQUEST_SUCCESS
} from "../actions/door"

const state = {
    Status: null,
    List: []
}
const getters = {
    IS_DOORS_LOADED: (state) => !!(
        state.List
        && typeof state.List !== "undefined"
        && state.List.length > 0
    ),
    DOORS_AMOUNT: (state) => state.List.length,
    GET_DOOR_BY_ID: (state) => (id) => state.List.find((x) => x.doorId === id)
}

const actions = {
    [DOOR_LIST_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
        commit(DOOR_LIST_REQUEST)
        HTTP({
            url: "door",
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.data && response.status === 200) {
                    commit(DOOR_LIST_REQUEST_SUCCESS, response.data)
                }
                else {
                    Helpers.notify(
                        "is-danger",
                        i18n.t("Message.Backend.NoData")
                    )
                }
            })
            .catch((error) => {
                reject(error)
                commit(DOOR_LIST_REQUEST_ERROR, error)
            })
    }),
    [DOOR_EDIT_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(DOOR_EDIT_REQUEST)
        HTTP({
            url: `door/${payload.Id}`,
            data: payload.Form,
            method: "PUT"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status && response.status === 204) {
                    commit(DOOR_EDIT_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(DOOR_EDIT_REQUEST_ERROR, error)
            })
    }),
    [DOOR_UPDATE_REQUEST]: ({ commit }) => new Promise((resolve, reject) => {
        commit(DOOR_UPDATE_REQUEST)
        HTTP({
            url: "door/update",
            method: "GET"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status === 200) {
                    commit(DOOR_UPDATE_REQUEST_SUCCESS)
                }
                else {
                    Helpers.notify(
                        "is-danger",
                        i18n.t("Message.Backend.NoData")
                    )
                }
            })
            .catch((error) => {
                reject(error)
                commit(DOOR_UPDATE_REQUEST_ERROR, error)
            })
    }),
    [DOOR_DELETE_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(DOOR_DELETE_REQUEST)
        HTTP({
            url: `door/${payload}`,
            method: "DELETE"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status && response.status === 204) {
                    commit(DOOR_DELETE_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(DOOR_DELETE_REQUEST_ERROR, error)
            })
    }),
    [DOOR_IMAGE_DELETE_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
        commit(DOOR_IMAGE_DELETE_REQUEST)
        HTTP({
            url: `door/image/${payload}`,
            method: "DELETE"
        })
            .then((response) => {
                resolve(response)
                if (response && response.status && response.status === 204) {
                    commit(DOOR_IMAGE_DELETE_REQUEST_SUCCESS)
                }
                else Helpers.notify("is-danger", i18n.t("Message.Backend.NoData"))
            })
            .catch((error) => {
                reject(error)
                commit(DOOR_IMAGE_DELETE_REQUEST_ERROR, error)
            })
    })
}

const mutations = {
    [DOOR_LIST_REQUEST]: (state) => {
        state.Status = "loading"
    },
    [DOOR_LIST_REQUEST_SUCCESS]: (state, payload) => {
        state.Status = "success"
        state.List = payload
    },
    [DOOR_LIST_REQUEST_ERROR]: (state, payload) => {
        state.Status = "error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [DOOR_EDIT_REQUEST]: (state) => {
        state.Status = "editing"
    },
    [DOOR_EDIT_REQUEST_SUCCESS]: (state) => {
        state.Status = "edit success"
    },
    [DOOR_EDIT_REQUEST_ERROR]: (state, payload) => {
        state.Status = "edit error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [DOOR_UPDATE_REQUEST]: (state) => {
        state.Status = "updating"
    },
    [DOOR_UPDATE_REQUEST_SUCCESS]: (state) => {
        state.Status = "update success"
    },
    [DOOR_UPDATE_REQUEST_ERROR]: (state, payload) => {
        state.Status = "update error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [DOOR_DELETE_REQUEST]: (state) => {
        state.Status = "deleting"
    },
    [DOOR_DELETE_REQUEST_SUCCESS]: (state) => {
        state.Status = "delete success"
    },
    [DOOR_DELETE_REQUEST_ERROR]: (state, payload) => {
        state.Status = "delete error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    },
    [DOOR_IMAGE_DELETE_REQUEST]: (state) => {
        state.Status = "deleting image"
    },
    [DOOR_IMAGE_DELETE_REQUEST_SUCCESS]: (state) => {
        state.Status = "delete image success"
    },
    [DOOR_IMAGE_DELETE_REQUEST_ERROR]: (state, payload) => {
        state.Status = "delete image error"
        if (payload && payload.response && payload.response.data && payload.response.data.error) {
            Helpers.notify("is-danger", payload.response.data.error)
        }
        else Helpers.notify("is-danger", i18n.t("Message.Backend.Default"))
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
