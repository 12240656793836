export const DOORPROVIDER_LIST_REQUEST = "DOORPROVIDER_LIST_REQUEST"
export const DOORPROVIDER_LIST_REQUEST_SUCCESS = "DOORPROVIDER_LIST_REQUEST_SUCCESS"
export const DOORPROVIDER_LIST_REQUEST_ERROR = "DOORPROVIDER_LIST_REQUEST_ERROR"

export const DOORPROVIDER_ADD_REQUEST = "DOORPROVIDER_ADD_REQUEST"
export const DOORPROVIDER_ADD_REQUEST_SUCCESS = "DOORPROVIDER_ADD_REQUEST_SUCCESS"
export const DOORPROVIDER_ADD_REQUEST_ERROR = "DOORPROVIDER_ADD_REQUEST_ERROR"

export const DOORPROVIDER_EDIT_REQUEST = "DOORPROVIDER_EDIT_REQUEST"
export const DOORPROVIDER_EDIT_REQUEST_SUCCESS = "DOORPROVIDER_EDIT_REQUEST_SUCCESS"
export const DOORPROVIDER_EDIT_REQUEST_ERROR = "DOORPROVIDER_EDIT_REQUEST_ERROR"

export const DOORPROVIDER_DELETE_REQUEST = "DOORPROVIDER_DELETE_REQUEST"
export const DOORPROVIDER_DELETE_REQUEST_SUCCESS = "DOORPROVIDER_DELETE_REQUEST_SUCCESS"
export const DOORPROVIDER_DELETE_REQUEST_ERROR = "DOORPROVIDER_DELETE_REQUEST_ERROR"
