<template>
    <sidebar-menu
        ref="SideMenu"
        :menu="menu"
        theme="white-theme"
        width="14em"
        @toggle-collapse="onToggleCollapse"
    >
        <span slot="toggle-icon">
            <b-icon icon="bars" />
        </span>
        <span slot="dropdown-icon">
            <b-icon icon="caret-right" />
        </span>
    </sidebar-menu>
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu"
import { SYSTEM_STATUS_REQUEST } from "@/store/actions/system"

export default {
    name: "Sidebar",
    components: {
        SidebarMenu
    },
    data() {
        return {
            DoorProvider: {
                IronLogic: {
                    href: { name: "IronLogic-List" },
                    title: this.$t("Interface.Sidebar.IronLogic"),
                    icon: {
                        element: "b-icon",
                        attributes: {
                            icon: "microchip",
                            size: "is-small"
                        }
                    }
                },
                AnotherOne: {
                    // Можно добавить ещё несколько
                }
            }
        }
    },
    computed: {
        DoorProviders() {
            const DoorProvidersArray = []
            if (this.$store.getters.IS_DOORPROVIDER_IRONLOGIC_ADDED) DoorProvidersArray.push(this.DoorProvider.IronLogic)
            return DoorProvidersArray
        },
        menu() {
            return [
                {
                    component: () => import("@/components/Decorative/Logo.vue")
                },
                {
                    href: { name: "Dashboard" },
                    title: this.$t("Interface.Sidebar.Dashboard"),
                    icon: {
                        element: "b-icon",
                        attributes: {
                            icon: "border-all",
                            size: "is-small"
                        }
                    }
                },
                {
                    href: { name: "Key-List" },
                    title: this.$t("Interface.Sidebar.Keys"),
                    icon: {
                        element: "b-icon",
                        attributes: {
                            icon: "key",
                            size: "is-small"
                        }
                    }
                },
                {
                    href: { name: "Door-List" },
                    title: this.$t("Interface.Sidebar.Doors"),
                    icon: {
                        element: "b-icon",
                        attributes: {
                            icon: "door-open",
                            size: "is-small"
                        }
                    }
                },
                {
                    href: { name: "Log-List" },
                    title: this.$t("Interface.Sidebar.Logs"),
                    icon: {
                        element: "b-icon",
                        attributes: {
                            icon: "clipboard-list",
                            size: "is-small"
                        }
                    }
                },
                {
                    component: () => import("@/components/Decorative/HR.vue")
                },
                {
                    header: true,
                    title: this.$t("Interface.Sidebar.Settings"),
                    hiddenOnCollapse: true
                },
                {
                    href: { name: "Account" },
                    title: this.$t("Interface.Sidebar.Account"),
                    icon: {
                        element: "b-icon",
                        attributes: {
                            icon: "user-circle",
                            size: "is-small"
                        }
                    }
                },
                {
                    href: { name: "Contract" },
                    title: this.$t("Interface.Sidebar.Contract"),
                    icon: {
                        element: "b-icon",
                        attributes: {
                            icon: "file-signature",
                            size: "is-small"
                        }
                    }
                },
                {
                    href: { name: "DoorProvider-List" },
                    title: this.$t("Interface.Sidebar.Providers"),
                    icon: {
                        element: "b-icon",
                        attributes: {
                            icon: "id-badge",
                            size: "is-small"
                        }
                    },
                    child: this.DoorProviders
                }
            ]
        }
    },
    mounted() {
        if (window.innerWidth < 1024) this.$refs.SideMenu.onToggleClick()
        window.addEventListener("resize", this.handleWindowResize)

        this.checkSystemStatus()
    },
    methods: {
        onToggleCollapse() {
            document.getElementById("app").classList.toggle("collapsed")
        },
        handleWindowResize(event) {
            if (event.currentTarget.innerWidth > 1023 && document.getElementById("app").classList.contains("collapsed")) {
                this.$refs.SideMenu.onToggleClick()
            }
            else if (event.currentTarget.innerWidth <= 1023 && !document.getElementById("app").classList.contains("collapsed")) {
                this.$refs.SideMenu.onToggleClick()
            }
        },
        checkSystemStatus() {
            if (!this.$store.getters.IS_SYSTEM_STATUS_LOADED) {
                this.$store.dispatch(SYSTEM_STATUS_REQUEST)
            }
        }
    }
}
</script>
