import { parseNumber as ParseNumber } from "libphonenumber-js"
import { extend, configure, setInteractionMode } from "vee-validate"
import {
    required,
    confirmed,
    numeric,
    integer,
    // eslint-disable-next-line
    min_value,
    // eslint-disable-next-line
    max_value,
    digits,
    email,
    min
} from "vee-validate/dist/rules"
import i18n from "../i18n"

setInteractionMode("eager")

configure({
    defaultMessage: (_, values) => i18n.t(`validation.${values._rule_}`, values)
})

// extend("required", {
//     ...required,
//     message: i18n.t("Validation.Required")
// })
extend("required", required)
extend("confirmed", confirmed)
extend("numeric", numeric)
extend("integer", integer)
extend("min_value", min_value)
extend("max_value", max_value)
extend("digits", digits)
extend("email", email)
extend("min", min)

extend("utf8", {
    validate(value) {
        // Basic Latin-1 Letter + Numbers Regular Expression
        return /^[0-9A-z\u00C0-\u00ff\s'.,-/#!$%^&*;:{}=\-_`~()]+$/.test(value)
    }
})

extend("phone", {
    validate(value) {
        let result = false
        if (value != null && value.length > 6) {
            // Проверка валидности номера телефона с помощью libphonenumber
            result = new ParseNumber(value, { extended: true }).valid
        }
        return result
    },
    message: i18n.t("Validation.Phone")
})

extend("login", {
    validate(value) {
        let result = false

        // Это телефон
        if (value != null && value.startsWith("+") && value.length > 6) {
            // Проверка валидности номера телефона с помощью libphonenumber
            result = new ParseNumber(value, { extended: true }).valid
        }
        // Это email
        else if (value != null && value.includes("@")) {
            // Email Address Regular Expression That 99.99% Works
            result = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                value
            )
        }
        return result
    },
    message: i18n.t("Validation.EmailPhone")
})

extend("bank-rs", {
    params: ["target"],
    validate(value, { target }) {
        let result = false
        let rs = value

        if (typeof rs === "number") {
            rs = rs.toString()
        }
        else if (typeof rs !== "string") {
            rs = ""
        }

        if ((!rs.length) || (/[^0-9]/.test(rs)) || (rs.length !== 20)) {
            result = false
        }
        else {
            const bikRs = target.toString().slice(-3) + rs
            let checksum = 0
            const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1]
            coefficients.forEach((index) => {
                checksum += coefficients[index] * (bikRs[index] % 10)
            })
            if (checksum % 10 === 0) {
                result = true
            }
            else result = false
        }
        return result
    },
    message: i18n.t("Validation.BankRS")
})

extend("float", {
    validate(value) {
        return /^[+-]?\d+(\.\d+)?$/.test(value)
    },
    message: i18n.t("Validation.Float")
})
