<template>
    <div class="account">
        <font-awesome-icon
            :icon="['fas', 'user-circle']"
            class="account-pic fa-2x"
        />
        <div class="account-info">
            <span class="account-info-phone">
                {{ AccountPhone }}
                <IconBoolean
                    :value="IsPhoneVerified"
                    hint-true="Phone is confirmed"
                    hint-false="Phone isn't confirmed"
                    :hint-color="IsPhoneVerified ? 'is-success' : 'is-danger'"
                />
            </span>
            <span class="account-info-email">
                {{ AccountEmail }}
                <IconBoolean
                    :value="IsEmailConfirmed"
                    size="fa-xs"
                    hint-true="Email is confirmed"
                    hint-false="Email isn't confirmed"
                    :hint-color="IsEmailConfirmed ? 'is-success' : 'is-danger'"
                />
            </span>
        </div>
        <b-loading
            :is-full-page="false"
            :active.sync="IsLoading"
            :can-cancel="false"
        />
    </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import LoadingState from "@/mixins/LoadingState"
import IconBoolean from "@/components/Widget/IconBoolean"
import { ACCOUNT_REQUEST } from "@/store/actions/account"

export default {
    name: "WidgetAccount",
    components: {
        IconBoolean
    },
    mixins: [LoadingState],
    computed: {
        AccountPhone() {
            return `+${this.$store.state.Account.Phone}`
        },
        AccountEmail() {
            return this.$store.state.Account.Email
        },
        IsPhoneVerified() {
            return this.$store.getters.IS_PHONE_VERIFIED
        },
        IsEmailConfirmed() {
            return this.$store.getters.IS_EMAIL_CONFIRMED
        }
    },
    mounted() {
        this.loadJivoScript()
        this.switchLoading()
        this.$store
            .dispatch(ACCOUNT_REQUEST)
            .then(() => {
                const UserName = this.$store.state.Account.Name
                const UserEmail = this.$store.state.Account.Email
                const UserPhone = `+${this.$store.state.Account.Phone.replace(/\s/g, "")}`
                window.jivo_onLoadCallback = function () {
                    jivo_api.setContactInfo({
                        name: UserName,
                        email: UserEmail,
                        phone: UserPhone
                    })
                    // console.log(jivo_api.getContactInfo())
                }
            })
            .finally(() => this.switchLoading())
    },
    methods: {
        loadJivoScript() {
            const script = document.createElement("script")
            script.src = "//code-ya.jivosite.com/widget/Oaz5iMHIM7"
            script.type = "text/javascript"
            script.async = "async"
            document.getElementsByTagName("head")[0].appendChild(script)
        }
    }
}
</script>
